.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ml-10 {
    margin-left: 10px;
}
.mb-3 {
    margin-bottom: 0.7rem !important;
}
.card-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.card-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DCDCDC;
    margin-bottom: 14PX;

}

.font-weight-normal {
    font-weight: 400 !important;
}
.item-profile-wrapper {
    text-align: start;
}

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}
.h-100 {
    height: 100%;
}
.reciept-container {
    width: 45%;
    padding: 20px;
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reciept-heading {
    font-weight: 600;
    font-size: 18px;
}

/* .circle-button {
    background: #333;
    padding: 25px 20px;
    color: #fff;
    font-size: 23px;
} */
.upload-btn {
    display: flex;
    justify-content: flex-end;
}

.table-container {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
}

.table-sub-heading {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0;
}

.p-0{
    padding: 0 !important;
}

.mt-40 {
    margin-top: 40px;
}
.mt-4 {
    margin-top: 1rem;
}

.filter-row {
    display: flex;
    align-items: center;
}

.mr-40 {
    margin-right: 40px;
}

.centered-heading {
    text-align: center;
    font-weight: 550;
    font-size: 2rem;
}

.dashboard .ant-pagination-options {
    display: none !important;
}

.pdf-display {
    align-items: center;
    display: flex;
    flex-direction: column;
}