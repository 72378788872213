.credit-btn {
    margin-right: 0.6rem;
    margin-top: 0.6rem;
}

.credit-btn:last-child {
    margin-right: 0;
}
.item-profile-p-label {
    font-size: 0.8rem !important;
}

.d-flex {
    display: flex;
}

.justify-content-between {
    justify-content: space-between;
}

.d-block {
    display: block;
}

.align-items-center {
    align-items: center;
}

.mr-0 {
    margin-right: 0rem !important;
}

.mb-0 {
    margin-bottom: 0rem !important;
}

.ml-0 {
    margin-left: 0rem !important;
}

.mt-0 {
    margin-top: 0rem !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mr-3 {
    margin-right: 0.7rem !important;
}

.mb-3 {
    margin-bottom: 0.7rem !important;
}

.ml-3 {
    margin-left: 0.7rem !important;
}

.mt-3 {
    margin-top: 0.7rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pointer {
    cursor: pointer !important;
}

.text-lowercase {
    text-transform: lowercase !important;
}

.w-70 {
    width: 70%;
}

.w-145p {
    width: 145px;
}

.mw-108p {
    min-width: 108px;
}
.flex-nowrap {
    flex-wrap: nowrap !important;
}

.outerOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.company-wrapper{
    position: relative;
}

.closeCompanyPopUp {
    position: absolute;
    bottom: 3rem;
    right: 6rem;
    background: white;
}